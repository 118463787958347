<template>
  <app-module-view>
    <template slot="body">
      <div class="row">
        <div class="col-lg-12">

        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'

export default {
  components: {
    appModuleView: ModuleView
  }
}
</script>
